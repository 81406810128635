import $ from "jquery"
import select2 from "select2"

window.select2 = select2()

$.fn.select2.defaults.set("theme", "bootstrap4");

const matchDataSearch = function(params, data) {
  if ($.trim(params.term) === '') {
    return data;
  }

  if (typeof data.element.dataset.search === 'undefined') {
    return null;
  }

  if (data.element.dataset.search.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
    return data;
  }

  return null;
}

$(document).on('turbo:load', function () {
  $('#recipe_tag_ids').select2({
    tags: true,
    tokenSeparators: [",", ";"]
  });

  const recipe_part_init = function () {
    $('.recipe_part_ingredients_unit select').select2()
    $('.recipe_part_ingredients_grocery select').select2({
      matcher: matchDataSearch,
      tags: true
    })
  }

  recipe_part_init()

  $('#ingredients').on('cocoon:after-insert', function (e, insertedItem) {
    recipe_part_init();
  })
})

/**** Source: https://stackoverflow.com/questions/20989458/select2-open-dropdown-on-focus ****/

// on first focus (bubbles up to document), open the menu
$(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
  $(this).closest(".select2-container").siblings('select:enabled').select2('open');
});

$(document).on('turbolinks:load', function () {
  // steal focus during close - only capture once and stop propogation
  $('select.select2').on('select2:closing', function (e) {
    $(e.target).data("select2").$selection.one('focus focusin', function (e) {
      e.stopPropagation();
    });
  });
})
